<template>
  <v-tooltip
    v-model="showTooltip"
    :top="tooltipPosition === 'top' || (!tooltipPosition && tooltip)"
    :bottom="tooltipPosition === 'bottom'"
    :left="tooltipPosition === 'left'"
    :right="tooltipPosition === 'right'"
    v-bind:disabled="(!tooltip || tooltip === '') && !tooltipPosition"
  >
    <template v-slot:activator="{ on: tooltipOn }">
      <v-textarea
        v-if="mode === 'text' || mode === 'i18n-text' || mode === 'raw-text'"
        v-on="tooltipOn"
        v-bind:append-icon="appendIcon"
        v-bind:append-outer-icon="appendOuterIcon"
        v-bind:auto-grow="autoGrow"
        v-bind:autofocus="autofocus"
        v-bind:background-color="backgroundColor"
        v-bind:clear-icon="clearIcon"
        v-bind:clearable="clearable"
        v-bind:color="color"
        v-bind:counter="counter"
        v-bind:dark="dark"
        v-bind:dense="dense"
        v-bind:disabled="disabled"
        v-bind:error-count="errorCount"
        v-bind:error-messages="errors"
        v-bind:filled="isFilled"
        v-bind:flat="flat"
        v-bind:full-width="fullWidth"
        v-bind:height="height"
        v-bind:hide-details="isHideDetails"
        v-bind:hint="hint"
        v-bind:id="id"
        v-bind:label="caption"
        v-bind:light="light"
        v-bind:loader-height="loaderHeight"
        v-bind:loading="loading"
        v-bind:messages="messages"
        v-bind:outlined="isOutline"
        v-bind:persistent-hint="persistentHint"
        v-bind:placeholder="placeholderText"
        v-bind:prefix="prefix"
        v-bind:prepend-icon="prependIcon"
        v-bind:prepend-inner-icon="prependInnerIcon"
        v-bind:readonly="readonly"
        v-bind:reverse="isReverse"
        v-bind:rounded="rounded"
        v-bind:row-height="rowHeight"
        v-bind:rows="rows"
        v-bind:rules="rules"
        v-bind:shaped="shaped"
        v-bind:single-line="singleLine"
        v-bind:solo="solo"
        v-bind:solo-inverted="soloInverted"
        v-bind:success="success"
        v-bind:success-messages="successMessages"
        v-bind:suffix="suffix"
        v-bind:type="type"
        v-bind:validate-on-blur="validateOnBlur"
        :maxlength="maxlength"
        v-model="variable"
        v-bind:class="classes"
        @focus="onfocus"
        @blur="onblur"
        @click:append-outer="clickAppendOuter"
        @click:append="clickAppend"
        @click:prepend-outer="clickPrependOuter"
        @click:prepend="clickPrepend"
        @keyup.alt.up.prevent="changeLang(-1)"
        @keyup.alt.down.prevent="changeLang(1)"
        @change="doChanged"
      >
        <template v-slot:append>
          <v-menu
            v-if="mode === 'i18n-text'"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                text
                small
                dark
                color="primary"
                class="ma-0"
                style="min-width: 0"
                >{{ language }}</v-btn
              >
            </template>

            <v-list>
              <v-list-item
                v-for="language in $store.state.common.app_languages"
                v-bind:key="language.code"
                @click="langCode = language.code"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ language.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-textarea>

      <v-text-field
        v-else
        v-on="tooltipOn"
        v-bind:append-icon="appendIcon"
        v-bind:append-outer-icon="appendOuterIcon"
        v-bind:autofocus="autofocus"
        v-bind:background-color="backgroundColor"
        v-bind:clear-icon="clearIcon"
        v-bind:clearable="clearable"
        v-bind:color="color"
        v-bind:counter="counter"
        v-bind:dark="dark"
        v-bind:dense="isDense"
        v-bind:disabled="disabled"
        v-bind:error-count="errorCount"
        v-bind:error-messages="errors"
        v-bind:filled="isFilled"
        v-bind:flat="flat"
        v-bind:full-width="fullWidth"
        v-bind:height="height"
        v-bind:hide-details="isHideDetails"
        v-bind:hint="hint"
        v-bind:id="id"
        v-bind:label="caption"
        v-bind:light="light"
        v-bind:loader-height="loaderHeight"
        v-bind:loading="loading"
        v-bind:messages="messages"
        v-bind:outlined="isOutline"
        v-bind:persistent-hint="persistentHint"
        v-bind:placeholder="placeholderText"
        v-bind:prefix="prefix"
        v-bind:prepend-icon="prependIcon"
        v-bind:prepend-inner-icon="prependInnerIcon"
        v-bind:readonly="mode === 'calendar' ? true : readonly"
        v-bind:reverse="isReverse"
        v-bind:rounded="isRounded"
        v-bind:rules="rules"
        v-bind:shaped="shaped"
        v-bind:single-line="singleLine"
        v-bind:solo="isSolo"
        v-bind:solo-inverted="soloInverted"
        v-bind:success="success"
        v-bind:success-messages="successMessages"
        v-bind:suffix="suffix"
        v-bind:type="type"
        v-bind:validate-on-blur="validateOnBlur"
        :maxlength="maxlength"
        v-model="variable"
        v-bind:class="classes"
        @focus="onfocus"
        @blur="onblur"
        @click:append-outer="clickAppendOuter"
        @click:append="clickAppend"
        @click:prepend-outer="clickPrependOuter"
        @click:prepend="clickPrepend"
        @keyup.alt.up.prevent="changeLang(-1)"
        @keyup.alt.down.prevent="changeLang(1)"
        @keyup.enter="doAction"
        @change="doChanged"
      >
        <template v-slot:prepend>
          <slot name="before"></slot>
        </template>
        <template v-slot:prepend-inner>
          <slot name="before-inner"></slot>
        </template>
        <template v-slot:append-outer>
          <slot name="after"></slot>
        </template>
        <template v-slot:append>
          <slot name="after-inner"></slot>
          <v-menu
            v-if="mode === 'i18n'"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                text
                small
                dark
                color="primary"
                class="ma-0"
                style="min-width: 0"
                >{{ language }}</v-btn
              >
            </template>

            <v-list>
              <v-list-item
                v-for="language in $store.state.common.app_languages"
                v-bind:key="language.code"
                @click="langCode = language.code"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ language.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-else-if="
              mode === 'calendar' ||
              mode === 'date-picker' ||
              mode === 'month-picker'
            "
            v-model="datePicker"
            v-bind:close-on-content-click="false"
            transition="slide-y-transition"
            v-bind:disabled="
              readonly || readonly === '' || disabled || disabled === ''
            "
            offset-y
            left
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-btn text icon small v-on="on" v-bind:disabled="disabled">
                <v-icon>fa-calendar-alt</v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="source"
              no-title
              scrollable
              :first-day-of-week="1"
              @input="datePicker = false"
            ></v-date-picker>
          </v-menu>
          <v-btn
            v-else-if="copy || copy === ''"
            icon
            class="rounded-lg"
            style="margin-top: -10px"
            color="accent"
            @click.stop="$copyText(copyValue)"
          >
            <v-icon>fa-copy</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>
    <span v-if="tooltip">{{ $app.i18n(tooltip) }}</span>
    <div v-else>
      <slot name="tooltip"></slot>
    </div>
  </v-tooltip>
</template>

<script>
const $moment = require('moment')

export default {
  props: [
    'centered',
    'copy',
    'copyValue',
    'errorText',
    'format',
    'extraClass',
    'mask',
    // Mode values:
    //  raw:            single line text input (no html decoding)
    //  raw-text:       multi line (textarea) text input (no html decoding)
    //  string:         single line text input
    //  text:           multi line (textarea) text input
    //  number:         single line number input
    //  datetime:       single line date time input without picker
    //  date:           single line date input without picker
    //  date-picker:    single line date input with picker
    //  month-picker:   single line month input with picker
    //  calendar:       single line date readonly input, with picker (to change value)
    //  i18n:           multilanguage single line text input with language picker
    //  i18n-text:      multilanguage textarea input with language picker
    //  i18n-list:      multilanguage single line text input, separate for any language
    //  i18n-text-list: multilanguage text area, separate for any language
    'mode',
    'name',
    'required',
    'stringify',
    'size',
    // Application style
    'inputStyle',
    'tooltip',
    'tooltipPosition',
    'tooltipFocused',
    'validator',
    'utc',
    // ------------------------------------------------------------------------------------------------------
    'appendIcon',
    'appendOuterIcon',
    'autoGrow',
    'autofocus',
    'backgroundColor',
    'clearIcon',
    'clearable',
    'color',
    'counter',
    'dark',
    'dense',
    'disabled',
    'error',
    'errorCount',
    'errorMessages',
    'filled',
    'flat',
    'fullWidth',
    'height',
    'hideDetails',
    'hint',
    'id',
    'label',
    'light',
    'loaderHeight',
    'loading',
    'maxlength',
    'messages',
    'noResize',
    'outlined',
    'persistentHint',
    'placeholder',
    'prefix',
    'prependIcon',
    'prependInnerIcon',
    'readonly',
    'reverse',
    'rounded',
    'rowHeight',
    'rows',
    'rules',
    'shaped',
    'singleLine',
    'solo',
    'soloInverted',
    'success',
    'successMessages',
    'suffix',
    'type',
    'validateOnBlur',
    'value'
  ],
  data: () => ({
    gsym: ' ',
    glen: 99,
    dsym: '.',
    dlen: 0,
    internal_error: undefined,
    langCode: undefined,
    datePicker: false,
    focused: false,
    showTooltip: false
  }),
  watch: {
    // Format string pattern 'gndc', where:
    //      g - group symbol
    //      n - group symbol count [optional]
    //      d - decimal symbol
    //      c - decimal count [optional]
    //
    //      SAMPLES: ' 3.2' or ',3.0' or ' .'
    format: {
      immediate: true,
      handler: function (value) {
        if (this.mode === 'number' && value && value !== '') {
          let fmt = value
          const gsym = fmt.substring(0, 1)
          fmt = fmt.substring(1)
          let glen = ''
          for (let i = 0; i < fmt.length; i++) {
            const sym = fmt.charAt(i)
            if (isNaN(Number(sym))) {
              fmt = fmt.substring(i)
              break
            }
            glen = glen + sym
          }
          glen = glen === '' ? 99 : Number(glen)

          const dsym = fmt.substring(0, 1)
          let dlen = fmt.substring(1)
          dlen = dlen === '' ? 99 : Number(dlen)
          this.dsym = dsym
          this.gsym = gsym
          this.glen = glen
          this.dlen = dlen
        }
      }
    },

    variable: {
      immediate: true,
      handler: function (value) {
        this.validate(value)
      }
    }
  },
  methods: {
    doAction: function (event) {
      this.$emit('action', event)
    },

    doChanged: function (event) {
      this.$emit('change', event)
    },

    clickAppendOuter: function (event) {
      this.$emit('click:append-outer', event)
    },

    clickAppend: function (event) {
      this.$emit('click:append', event)
    },

    clickPrependOuter: function (event) {
      this.$emit('click:prepend-outer', event)
    },

    clickPrepend: function (event) {
      this.$emit('click:prepend', event)
    },

    onfocus: function (event) {
      this.focused = true
      if (this.tooltipFocused) {
        this.showTooltip = true;
      }
      this.$emit('focus', event)
    },

    onblur: function (event) {
      this.focused = false
      if (this.tooltipFocused) {
        this.showTooltip = false;
      }
      this.$emit('blur', event)
    },

    changeLang: function (direction) {
      let set = 0
      for (let i = 0; i < this.$store.state.common.app_languages.length; i++) {
        if (this.langCode === this.$store.state.common.app_languages[i].code) {
          set = direction > 0 ? i + 1 : i - 1
          break
        }
      }

      set = set < 0 ? this.$store.state.common.app_languages.length - 1 : set
      set = set >= this.$store.state.common.app_languages.length ? 0 : set

      this.langCode = this.$store.state.common.app_languages[set].code
    },

    isEmpty: function (value) {
      return value === undefined || value === null || value === '' || value === 0
    },

    validate: function (value) {
      if (this.isEmpty(this.value)) {
        this.internal_error =
          this.required === undefined || this.required === false
            ? undefined
            : this.$app.i18n('system.global.RequiredField')
      } else {
        if (this.mode === 'number') {
          const tmp = String(this.value).replace(',', '.')
          if (isNaN(tmp)) {
            this.internal_error =
              this.errorText !== undefined
                ? this.$app.i18n(this.errorText)
                : this.$app.i18n('system.global.InvalidNumber')
          } else {
            this.internal_error = undefined
          }
        } else if (
          this.mode === 'date' ||
          this.mode === 'calendar' ||
          this.mode === 'date-picker' ||
          this.mode === 'month-picker'
        ) {
          const m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DD', true) : $moment(this.value, 'YYYY-MM-DD', true)
          if (!m.isValid()) {
            this.internal_error =
              this.errorText !== undefined
                ? this.$app.i18n(this.errorText)
                : this.$app.i18n('system.global.InvalidDate')
          } else {
            this.internal_error = undefined
          }
        } else if (
          this.mode === 'datetime'
        ) {
          const m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DDTHH:mm:ss', true) : $moment(this.value, 'YYYY-MM-DDTHH:mm:ss', true)
          if (!m.isValid()) {
            this.internal_error =
              this.errorText !== undefined
                ? this.$app.i18n(this.errorText)
                : this.$app.i18n('system.global.InvalidDate')
          } else {
            this.internal_error = undefined
          }
        } else {
          if (
            this.mask !== undefined &&
            this.mask !== null &&
            this.mask !== ''
          ) {
            const pattern = new RegExp(this.mask, 'ui')
            this.internal_error = pattern.test(this.value)
              ? undefined
              : this.errorText !== undefined
                ? this.$app.i18n(this.errorText)
                : this.$app.i18n('global.global.InvalidField')
          } else {
            this.internal_error = undefined
          }
        }
      }

      if (this.validator && this.name) {
        this.$set(this.validator, this.name, this.internal_error === undefined)
      }
    }
  },

  computed: {
    classes: function () {
      const align = this.centered || this.centered === '' ? 'text-center center-control ' : (this.mode === 'number' ? 'text-right right-control ' : ' ')

      let result = align + 'data-input rounded-lg ';

      if (this.size === 'large') {
        result += 'text-h4 '
      } else if (this.size === 'x-large') {
        result += 'text-h2 '
      }

      if (this.extraClass) {
        result += this.extraClass + ' '
      }

      return result;
    },

    isUTC: function () {
      return this.utc || this.utc === '';
    },

    isHideDetails: function () {
      return this.hideDetails === true || this.hideDetails === '';
    },

    isReverse: function () {
      return this.reverse === true || this.reverse === '';
    },

    isOutline: function () {
      return this.inputStyle && (this.outlined === undefined || this.outlined === null)
        ? this.inputStyle.inputOutline
        : this.outlined;
    },

    isDense: function () {
      return this.inputStyle && (this.dense === undefined || this.dense === null)
        ? this.inputStyle.inputDense
        : this.dense;
    },

    isSolo: function () {
      return this.inputStyle && (this.solo === undefined || this.solo === null)
        ? this.inputStyle.inputSolo
        : this.solo;
    },

    isFilled: function () {
      return this.inputStyle && (this.filled === undefined || this.filled === null)
        ? this.inputStyle.inputFilled
        : this.filled;
    },

    isRounded: function () {
      return this.inputStyle && (this.rounded === undefined || this.rounded === null)
        ? this.inputStyle.inputRounded
        : this.rounded;
    },

    language: function () {
      return this.langCode === undefined
        ? this.$store.state.common.app_language.code
        : this.langCode
    },

    errors: function () {
      if (
        this.error !== undefined &&
        this.error !== null &&
        this.error !== ''
      ) {
        return [this.error]
      } else if (this.internal_error !== undefined) {
        return [this.internal_error]
      } else {
        return undefined
      }
    },

    caption: function () {
      if (this.required || this.required === '') {
        return this.$app.i18n(this.label) + ' *'
      } else {
        return this.$app.i18n(this.label)
      }
    },

    placeholderText: function () {
      return this.$app.i18n(this.placeholder)
    },

    source: {
      get: function () {
        if (this.mode === 'datetime') {
          let m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DDTHH:mm:ss', true) : $moment(this.value, 'YYYY-MM-DDTHH:mm:ss', true);
          if (this.value !== undefined && this.value !== null && m.isValid()) {
            return m.format('YYYY-MM-DDTHH:mm:ss')
          } else {
            m = this.isUTC ? $moment.utc(new Date(), 'YYYY-MM-DDTHH:mm:ss', true) : $moment(new Date(), 'YYYY-MM-DDTHH:mm:ss', true)
            return m.format('YYYY-MM-DDTHH:mm:ss')
          }
        } else {
          let m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DD', true) : $moment(this.value, 'YYYY-MM-DD', true)
          if (this.value !== undefined && this.value !== null && m.isValid()) {
            return m.format('YYYY-MM-DD')
          } else {
            m = this.isUTC ? $moment.utc(new Date(), 'YYYY-MM-DD', true) : $moment(new Date(), 'YYYY-MM-DD', true)
            return m.format('YYYY-MM-DD')
          }
        }
      },
      set: function (value) {
        if (this.mode === 'datetime') {
          const m = this.isUTC ? $moment.utc(value, 'YYYY-MM-DDTHH:mm:ss', true) : $moment(value, 'YYYY-MM-DDTHH:mm:ss', true)
          if (this.stringify || this.stringify === '') {
            this.$emit('input', m.format('YYYY-MM-DDTHH:mm:ss'))
            this.$emit('change', value)
          } else {
            this.$emit('input', m.toDate())
            this.$emit('change', value)
          }
        } else {
          const m = this.isUTC ? $moment.utc(value, 'YYYY-MM-DD', true) : $moment(value, 'YYYY-MM-DD', true)
          if (this.stringify || this.stringify === '') {
            this.$emit('input', m.format('YYYY-MM-DD'))
            this.$emit('change', value)
          } else {
            this.$emit('input', m.toDate())
            this.$emit('change', value)
          }
        }
      }
    },

    variable: {
      get: function () {
        if (!this.isEmpty(this.value)) {
          if (this.mode === 'number') {
            const tmp = String(this.value).replace(',', '.')
            if (!isNaN(tmp)) {
              if (this.focused && this.value === 0) {
                return ''
              } else if (!this.focused || this.readonly === '' || this.readonly) {
                const num = Number(this.value).format(
                  this.dlen,
                  this.glen,
                  this.gsym,
                  this.dsym,
                  true
                );
                return Number(this.value) < 0 ? '- ' + num : num;
              }
            }
          } else if (
            this.mode === 'date' ||
            this.mode === 'calendar' ||
            this.mode === 'date-picker' ||
            this.mode === 'month-picker'
          ) {
            const m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DD', true) : $moment(this.value, 'YYYY-MM-DD', true)
            if (m.isValid()) {
              return m.format(this.format)
            }
          } else if (
            this.mode === 'datetime'
          ) {
            const m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DDTHH:mm:ss', true) : $moment(this.value, 'YYYY-MM-DDTHH:mm:ss', true)
            if (m.isValid()) {
              return m.format(this.format)
            }
          } else if (this.mode === 'i18n' || this.mode === 'i18n-text') {
            return this.value[this.language] === undefined
              ? undefined
              : decodeURIComponent(this.value[this.language])
          } else {
            if (
              this.mask === undefined ||
              this.mask === null ||
              this.mask === ''
            ) {
              if (this.mode === 'raw') {
                return this.value
              } else {
                return decodeURIComponent(this.value)
              }
            }
          }
        }

        return this.value
      },
      set: function (value) {
        if (this.isEmpty(value)) {
          if (
            this.mode === 'number' ||
            this.mode === 'datetime' ||
            this.mode === 'date' ||
            this.mode === 'calendar' ||
            this.mode === 'month-picker' ||
            this.mode === 'date-picker'
          ) {
            value = undefined
          }
        } else {
          if (this.mode === 'number') {
            const tmp = String(value).replace(',', '.')
            if (!isNaN(tmp)) {
              value = Number(tmp)
            }
          } else if (
            this.mode === 'datetime'
          ) {
            const m = this.isUTC ? $moment.utc(value, this.format, true) : $moment(value, this.format, true)
            if (m.isValid()) {
              if (this.stringify || this.stringify === '') {
                value = m.format('YYYY-MM-DDTHH:mm:ss')
              } else {
                value = m.toDate()
              }
            }
          } else if (
            this.mode === 'date' ||
            this.mode === 'calendar' ||
            this.mode === 'month-picker' ||
            this.mode === 'date-picker'
          ) {
            const m = this.isUTC ? $moment.utc(value, this.format, true) : $moment(value, this.format, true)
            if (m.isValid()) {
              if (this.stringify || this.stringify === '') {
                value = m.format('YYYY-MM-DD')
              } else {
                value = m.toDate()
              }
            }
          }
        }

        if (this.mode === 'i18n' || this.mode === 'i18n-text') {
          const multi = Object.assign({}, this.value)
          multi[this.language] = value
          value = multi
        }

        this.$emit('input', value)
        if (this.mode === 'date-picker') {
          this.$emit('action', value)
        }
      }
    }
  },

  beforeMount: function () {
    this.validate(this.variable)
  },

  beforeUpdate: function () {
    this.validate(this.variable)
  }
}
</script>

<style>
.right-control input {
  text-align: right !important;
}
</style>
